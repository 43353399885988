import React from "react"
import { graphql, Link  } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "../components/Elements/Img"
import SectionContainer from "../components/SectionContainer"
import VideoPlayer from "../components/VideoPlayer/index"
import { Container, Row, Col } from "../components/Grid"
import {
  SectionBody,
  AccentHeader,
  PageTitle,
} from "../components/Elements/index"

const WorkLink = styled(props => <Link {...props} />)`
  color: #414141;
  &:active {
    color: #414141;
  }

  &:visited {
    color: #414141;
  }
`

const BlueDivider = styled.div`
div{
  background: #00afd7;
}
`


const Footer = styled.footer`
  background: #6a115a;
  display: flex;
  height: 120px;
  align-items: center;
`

const RevomeMarginImage = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const RevomeMarginVideo = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const kimmtrakConnect = ({ data }) => (
  <Layout>
    <ThemeProvider
      theme={{
        backgroundColor: "#FFFFFF",
        primaryColor: "#414141",
        dividerColor: "#6a115a;",
        invert: {
          backgroundColor: "#5d6062",
        },
      }}
    >
      <>
        <Seo title="KIMMTRAK CONNECT" />
        <SectionContainer>
          <Container>
            <AccentHeader size="large" background="#ffffff">
              <WorkLink to="/">
                  The Work
                </WorkLink>
            </AccentHeader>
            <div style={{ paddingBottom: "30px" }}>
            <PageTitle style={{ color: "#78c800" }}>KIMMTRAK CONNECT<br/><small>Best Patient Engagement Campaign</small></PageTitle>
            </div>
            <Row>
              <Col md={8}>
                <BlueDivider>
                  <AccentHeader>The Situation</AccentHeader>
                </BlueDivider>
                <SectionBody size="regular">
                  Uveal melanoma is a rare and aggressive melanoma of the eye that effects approximately 2,000 patients per year with 50% developing metastatic disease. Before the approval of KIMMTRAK® (tebentafusp-tebn), patients with metastatic uveal melanoma (mUM) faced a dismal prognosis with no universally accepted standard of care. KIMMTRAK is an immunotherapy that engages the patient’s own T-cells to recognize and fight the aggressive opponent. Being the first and only FDA-approved treatment in mUM has brought optimism and hope to patients but unique challenges as well.  <br />
                  <br />
                  Weekly infusions of an immunotherapy may bring serious adverse reactions requiring lengthy monitoring, specific counseling, and logistical challenges that come with hospitalization. KIMMTRAK CONNECT, sponsored by Immunocore, was created to address these patient challenges by matching them with a dedicated nurse case manager. This specialized, personal care coordinator helps patients navigate their treatment experience at no cost. <br /><br />
                  Immunocore needed promotional assets to make patients, caregivers, and HCPs aware of the gratis support program, specifically the dedicated nurse care managers.
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer>
          <Img fluid={data.image1.childImageSharp.fluid} />
        </SectionContainer>

        <SectionContainer noTopMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px" }}>
            <Row>
              <Col md={4}>
                <Img fluid={data.image2.childImageSharp.fluid} />
              </Col>
              <Col md={4}>
                <Img fluid={data.image3.childImageSharp.fluid} />
              </Col>
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer invert noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", backgroundColor: "#5d6062", color: "#ffffff" }}>
            <Row>
              <Col md={8}>
                <BlueDivider>
                  <AccentHeader>The Idea</AccentHeader>
                </BlueDivider>
                <SectionBody size="regular">
                  Finding as few as 500-1000 patients spread across the US, while making a “connection” as the program name suggests, required meeting patients in the digital space augmented with targeted print resources. A robust website, a concise educational video, digital downloads and print materials, along with a social media presence all provided the launching pad for this specialized patient program. 
                  <br />
                  <br />
                  Three core pillars were created to simply and succinctly put the patient at ease knowing they would have their own personal team of experts to guide them through their treatments. The “quarterback” of their team was featured throughout the promotional materials in the form of actual bios and contact information of the regional nurse case manager who would be supporting them thus completing the connection.
                </SectionBody>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>

        <RevomeMarginVideo>
          <VideoPlayer
            poster={() => (
              <Img fluid={data.videoThumbnail.childImageSharp.fluid} />
            )}
            url="https://player.vimeo.com/video/790571771?h=cc1cc18408&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          />
        </RevomeMarginVideo>

        <SectionContainer noTopMargin noBottomMargin>
          <RevomeMarginImage>
            <Img fluid={data.image4.childImageSharp.fluid} />
          </RevomeMarginImage>
        </SectionContainer>


        <SectionContainer invert noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", backgroundColor: "#5d6062", color: "#ffffff" }}>
            <Row>
              <Col md={8}>
                <AccentHeader>The Execution</AccentHeader>                
                <SectionBody size="regular">
                  Complex branding elements, content heavy descriptors, and cumbersome functionality were all off the table when supporting patients with an aggressive eye cancer. Visual impairment accompanies this ruthless disease so we designed and deployed bold brilliant brand colors, utilized multiplied overlapping shape effects, and ADA compliant icon identifiers with large text formatting so patients could readily recognize pillared sections and pertinent enrollment information.<br/><br/>

                    The actual bios of their dedicated nurse case manager surrounded by educational support, financial assistance, and enrollment forms all immersed in powerful brand colors has in fact connected the patient, caregiver, and healthcare community to the promising potential to live longer with KIMMTRAK.
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <RevomeMarginImage>
            <Img fluid={data.image5.childImageSharp.fluid} />
          </RevomeMarginImage>
        </SectionContainer>
        
      </>
    </ThemeProvider>
  </Layout>
)

export default kimmtrakConnect

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "2023/kimmtrak-connect/image1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    image2: file(relativePath: { eq: "2023/kimmtrak-connect/image2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image3: file(relativePath: { eq: "2023/kimmtrak-connect/image3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image4: file(relativePath: { eq: "2023/kimmtrak-connect/image4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image5: file(relativePath: { eq: "2023/kimmtrak-connect/image5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    videoThumbnail: file(relativePath: { eq: "2023/kimmtrak-connect/banner1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
